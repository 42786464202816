import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  
  { path: 'home',
  children: [
    {
     path : '',
     loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    }
    ]
   },

   {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },

  { path: 'help',
  children: [
    {
     path : '',
     loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
    },

    {
     path : ':postId',
     loadChildren: () => import('./pages/post/post.module').then( m => m.PostPageModule)
    }
    ]
   },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },

  { path: 'orders',
  children: [
    {
     path : '',
     loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuard]
    },

    {
     path : ':orderId',
     loadChildren: () => import('./pages/orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule),
     canActivate: [AuthGuard]
    }
    ]
   },

   { path: 'orderdetails',
   children: [
     {
      path : ':editOrderId',
      loadChildren: () => import('./pages/editorder/editorder.module').then( m => m.EditorderPageModule),
      canActivate: [AuthGuard]
     }
     ]
    },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },

  { path: 'barcode',
  children: [
    {
     path : '',
    loadChildren: () => import('./pages/barcode/barcode.module').then( m => m.BarcodePageModule),
    canActivate: [AuthGuard]
    },

    {
     path : ':orderId',
     loadChildren: () => import('./pages/orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule),
     canActivate: [AuthGuard]
    }
    ]
   },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
